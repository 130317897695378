/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link} from "gatsby";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Continue your learning with the following resources:"), "\n", React.createElement("ul", null, React.createElement("li", null, React.createElement(Link, {
    to: "/polishing-the-chain"
  }, "Polishing the Chain Seminar Series")), React.createElement("li", null, React.createElement(Link, {
    to: "/bibliography"
  }, "Bibliography & Historical References")), React.createElement("li", null, React.createElement(Link, {
    to: "/links-to-learn-more"
  }, "Links to Learn More"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
